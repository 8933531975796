import React, { useState, useEffect, useRef } from 'react';
import Node from './Node';
import './CanvasArea.css';
import OpenAI from 'openai';
import { motion, useAnimationControls } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@tanstack/react-query';
import { Mixpanel } from './Mixpanel';

const CanvasArea = ({
  nodes,
  setNodes,
  isLoading,
  clickedNodes,
  setClickedNodes,
  setShowDetailModal,
  setIsLoadingContent,
  setResponseObject,
  setActiveSubQuestion,
  activeSubQuestion,
  showDetailModal,
  setShowSynthesisModal,
  mainQuestion,
  setIsLoadingSynthesis,
  reduxStoreData,
  serpAPIResponse,
}) => {
  const [isPanning, setIsPanning] = useState(false);
  const [startPanPosition, setStartPanPosition] = useState({ x: 0, y: 0 });
  const [activeParentId, setActiveParentId] = useState('');
  const [camera, setCamera] = useState({ x: 0, y: 0, z: 0.55 }); // z is the zoom level, starting at 1 (100%)

  const canvasRef = useRef();

  useEffect(() => {
    const canvasElement = canvasRef.current; // canvasRef should refer to the canvas DOM element
    if (canvasElement) {
      canvasElement.addEventListener('wheel', handleWheel, { passive: false });
      // Add any other event listeners specific to panning and zooming here
    }

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener('wheel', handleWheel);
        // Clean up any other event listeners here
      }
    };
  }, []);

  const eqControls = useAnimationControls();

  const eqInitialVariant = {
    scaleY: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  };

  const variants1 = {
    animate: {
      scaleY: [1, 1.4, 0.8, 1], // These values can be adjusted
      transition: {
        duration: 0.75,
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
      },
    },
  };

  const variants2 = {
    animate: {
      scaleY: [1, 1.4, 0.8, 1], // These values can be adjusted
      transition: {
        duration: 0.75,
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        delay: 0.3,
      },
    },
  };

  const variants3 = {
    animate: {
      scaleY: [1, 1.2, 0.8, 1], // These values can be adjusted
      transition: {
        duration: 0.75,
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        delay: 0.6,
      },
    },
  };

  const variants4 = {
    animate: {
      scaleY: [1, 1.4, 0.8, 1], // These values can be adjusted
      transition: {
        duration: 0.75,
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        delay: 0.9,
      },
    },
  };

  const variants5 = {
    animate: {
      scaleY: [1, 1.4, 0.8, 1], // These values can be adjusted
      transition: {
        duration: 0.75,
        ease: 'easeOut',
        repeat: Infinity,
        repeatType: 'mirror',
        delay: 1.2,
      },
    },
  };

  const gridBackgroundSize = 100000; // A large enough size to cover full content
  const gridStyle = {
    position: 'absolute',
    top: `${-gridBackgroundSize / 2}px`,
    left: `${-gridBackgroundSize / 2}px`,
    width: `${gridBackgroundSize}px`,
    height: `${gridBackgroundSize}px`,
    backgroundSize: '40px 40px',
    backgroundImage: `linear-gradient(to right, rgba(222, 218, 242, 0.5) 1px, transparent 1px),
       linear-gradient(to bottom, rgba(222, 218, 242, 0.5) 1px, transparent 1px)`,
    pointerEvents: 'none', // This prevents the grid from capturing mouse events
    transform: `translate(${camera.x}px, ${camera.y}px) scale(${camera.z})`,
    transformOrigin: '0 0',
  };

  const fetchChildNodeHeader = async (parentId, inputText) => {
    if (!inputText.trim() === '') return;

    const mainQuestion = nodes.find((node) => node.id === 'root')?.text || '';
    const parentNodeText =
      nodes.find((node) => node.id === parentId)?.text || '';

    // console.log('called fetchChildNodeHeader!');
    // console.log('FetchedChildNodeHeader, ParentNodeText:' + parentNodeText);

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'Your role is of a world class writer with an impeccable ability to summarise content and distill it to its essence communicated briefly and clearly',
          },
          {
            role: 'user',
            content: `Take a deep breath. I want you to act as a world-class writer with an impeccable ability to summarise content and distil it to its essence communicated briefly and clearly. 

            I want you to take this question ${inputText} and briefly summarise it into a header summarising the question in a maximum of 6 words. Your the header should also particuarly consider the parent question the question I sent belongs to and additionally the main research question I am exploring all this through, this should be reflected in the summary. Here is the parent question: ${parentNodeText} and here is my main research question: ${mainQuestion}
            
            The only thing you would write back to me should strictly ONLY be the header text. 
            
            Please format your response in a structured JSON object as shown below.
            
            E.g. for the question “What factors contribute to the high costs of wind energy?”, the ONLY response from you should be {"header": “Key cost factors of wind energy”}.
            
            E.g. for the question “How can technological advancements reduce the costs of renewable energy”, the ONLY response from you should be {"header": “Reducing energy costs with technology”}.
            
            ONLY GIVE ME A RESPONSE WITH THE SUMMARISED HEADER TEXT, AND NOTHING ELSE. THIS IS VERY IMPORTANT.  Nothing else unnecessary in terms of explanations of words should be included in your response.
            `,
          },
        ],
        model: 'gpt-4o-mini',
        response_format: { type: 'json_object' },
      });

      // console.log(completion.choices[0].message.content);
      const output = completion.choices[0].message.content;
      const { header } = JSON.parse(output);
      return header;
    } catch (error) {
      console.error('Error calling the OpenAI Chat API:', error);
    }
  };

  const addChildNode = async (parentId, inputText) => {
    if (!inputText.trim() === '') return;

    const fetchedHeader = await fetchChildNodeHeader(parentId, inputText);

    // Find the parent node
    const parentNode = nodes.find((node) => node.id === parentId);
    if (!parentNode) {
      console.error('Parent node not found');
      return;
    }

    // Check if parentNode has children already
    const childrenOfParent = nodes.filter((node) => node.parentId === parentId);

    // Determine the new node number
    let newNumber;
    if (childrenOfParent.length === 0) {
      if (parentNode.number.endsWith('.0')) {
        // Correctly increment the last digit from 0 to 1
        newNumber = `${parentNode.number.slice(0, -1)}1`;
      } else {
        newNumber = `${parentNode.number}.1`;
      }
    } else {
      const lastChildNumber = childrenOfParent.reduce((max, node) => {
        const currentNumber = Number(node.number.split('.').pop());
        return Math.max(max, currentNumber);
      }, 0);
      if (parentNode.number.endsWith('.0')) {
        // If the parent's number ends with .0 and has existing children, correctly increment based on the last child
        newNumber = `${parentNode.number.slice(0, -1)}${lastChildNumber + 1}`;
      } else {
        newNumber = `${parentNode.number}.${lastChildNumber + 1}`;
      }
    }

    // Create the child node
    const childNode = {
      id: uuidv4(),
      parentId: parentId,
      position: {
        x: parentNode.position.x + 50,
        y: parentNode.position.y + 200,
      },
      header: fetchedHeader,
      number: newNumber,
      text: inputText,
      level: parentNode.level + 1,
      topmostSubQuestionId: parentNode.topmostSubQuestionId,
    };

    // For React state update, use this instead if nodes is part of state:
    setNodes((prevNodes) => [...prevNodes, childNode]);
    Mixpanel.track('Add node');
  };

  const canvasContentRef = useRef(null); // Add a ref for the canvas-content

  // Call adjustCanvasSize whenever nodes or scaleFactor change

  const MAX_ZOOM = 1.2; // 120% Zoom
  const MIN_ZOOM = 0.55; // 55% Zoom
  const zoomStep = 0.1; // Defines how much the zoom changes per step

  const zoomIn = () => {
    setCamera((prevCamera) => ({
      ...prevCamera,
      z: Math.min(prevCamera.z + zoomStep, MAX_ZOOM), // Use the same max zoom limit
    }));
  };

  const zoomOut = () => {
    setCamera((prevCamera) => ({
      ...prevCamera,
      z: Math.max(prevCamera.z - zoomStep, MIN_ZOOM), // Use the same min zoom limit
    }));
  };

  const handleWheel = (e) => {
    e.preventDefault();
    const { deltaX, deltaY, ctrlKey } = e;
    if (ctrlKey) {
      // Apply zoom limits inside this block
      setCamera((prevCamera) => {
        let newZoom = prevCamera.z - deltaY * 0.01;
        newZoom = Math.min(Math.max(newZoom, MIN_ZOOM), MAX_ZOOM); // Enforce min/max zoom levels
        return {
          ...prevCamera,
          z: newZoom,
        };
      });
    } else {
      // Normal panning with the mouse wheel or trackpad
      setCamera((prevCamera) => ({
        ...prevCamera,
        x: prevCamera.x - deltaX,
        y: prevCamera.y - deltaY,
      }));
    }
  };

  const handleMouseDown = (e) => {
    if (e.button === 1) {
      // Middle mouse button
      setIsPanning(true);
      setStartPanPosition({ x: e.clientX - camera.x, y: e.clientY - camera.y });
      e.preventDefault();
    }
  };

  const handleMouseMove = (e) => {
    if (isPanning) {
      setCamera((prevCamera) => ({
        ...prevCamera,
        x: e.clientX - startPanPosition.x,
        y: e.clientY - startPanPosition.y,
      }));
    }
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  useEffect(() => {
    // console.log(activeSubQuestion); // This will log the updated state after changes
  }, [activeSubQuestion]);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_API_KEY,
    organization: process.env.REACT_APP_ORG_KEY,
    dangerouslyAllowBrowser: true,
  });

  const OpenAICall = async (subQuestion, parentId) => {
    if (!subQuestion) return;

    // console.log('called!');

    const mainQuestion = nodes.find((node) => node.id === 'root')?.text || '';
    const parentNodeText =
      nodes.find((node) => node.id === parentId)?.text || '';

    // console.log('OpenAICall, ParentNodeText:' + parentNodeText);
    serpAPIResponse(mainQuestion, subQuestion);

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'Your role is of a superintelligent web crawler and expert curator of sources for relevant research content, working diligently and carefully akin to a world-class McKinsey consultant.',
          },
          {
            role: 'user',
            content: `Take a deep breath. Act as a highly proficient web crawler and content curator, emulating the meticulous research approach of a McKinsey consultant. You have 3 objectives: 1) Summarize: Provide a highly valable concise, precise, and intuitive bullet-pointed summary (only up to 150 words) on the specified question. 2) Curate: Identify and list 5 highly valuable and authoritative articles that discuss the question, including brief titles and URLs. 3) Expand on summary: Provide an additional expanded version of the previous summary (only up to 500 words) where you go into more depth covering the specified question clearly, precisely, and intuitively. Both these summaries should include highly valuable and targeted information, outlining essential but also critical aspects that clients themselves would easily not be aware of or think of. 

            Format your response as follows, adhering strictly to this JSON structure as an array with 3 objects:
                      
            {
                "summary": {
                  "text": "Your summary here, max 150 words."
                },
                "urls": [
                  {
                    "title": "Title here",
                    "domain": "Website domain here, e.g. www.nytimes.com",
                    "url": "URL here"
                  },
                  {
                    "title": "Title here",
                    "domain": "Website domain here, e.g. www.wikipedia.com",
                    "url": "URL here"
                  },
                  {
                    "title": "Title here",
                    "domain": "Website domain here, e.g. www.ft.com",
                    "url": "URL here"
                  },
                  {
                    "title": "Title here",
                    "domain": "Website domain here, e.g. www.nature.com",
                    "url": "URL here"
                  },
                  {
                    "title": "Title here",
                    "domain": "Website domain here, e.g. www.wsj.com",
                    "url": "URL here"
                  }
                ],
                "expandedSummary": {
                  "text": "Your expanded summary here, max 500 words"
                }
            }
            
            ONLY GIVE ME A RESPONSE WITH THIS STRICT json FORMAT INCLUDING A SUMMARY OF MAX 150 WORDS, 5 URLS WITH BRIEF TITLES, AN EXPANDED SUMMARY OF MAX 500 WORDS AND NOTHING ELSE. THIS IS VERY IMPORTANT. Nothing else unnecessary in terms of explanations of words should be included in your response. HERE IS THE KEY QUESTION I WANT YOU TO CONSIDER FOR THE SUMMARIES AND THE URLS: ${subQuestion}; and here is some extra nuanced background context for parent question of this sub-question I want help exploring: ${parentNodeText}, and here is the overarching main research question I am exploring all this through: ${mainQuestion}. This extra context of the parent and main research question should be considered for your summaries and the URLs you gather.
            `,
          },
        ],
        model: 'gpt-4o',
        response_format: { type: 'json_object' },
      });

      // console.log(completion.choices[0].message.content);
      const output = completion.choices[0].message.content;
      const outputObject = JSON.parse(output);
      // console.log(outputObject);
      return outputObject;
    } catch (error) {
      console.error('Error calling the OpenAI Chat API:', error);
    }
  };

  const { status, error, data, isFetched } = useQuery({
    queryKey: ['OpenAICall', activeSubQuestion, activeParentId],
    queryFn: () => OpenAICall(activeSubQuestion, activeParentId),
    staleTime: Infinity,
  });

  useEffect(() => {
    // console.log('Status: ', status);

    if (status === 'pending') {
      setIsLoadingContent(true);
    } else if (status === 'success') {
      setIsLoadingContent(false);
      setResponseObject(data);
    }
  }, [status, showDetailModal]);

  // Handle data and side effects on successful data fetching

  const onDrag = (id, newX, newY) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === id ? { ...node, position: { x: newX, y: newY } } : node
      )
    );
  };

  const handleNodeClick = (nodeId) => {
    setClickedNodes((prevNodes) => {
      if (!prevNodes.includes(nodeId)) {
        return [...prevNodes, nodeId];
      }
      return prevNodes;
    });
  };

  const nodeDoubleClickHandler = (id) => {
    const nodeText = nodes.find((node) => node.id === id)?.text || '';
    const parentId = nodes.find((node) => node.id === id)?.parentId || '';
    const isNodeClicked = clickedNodes.includes(id);

    // console.log('nodeClicked: ', isNodeClicked);

    setActiveSubQuestion(nodeText);
    setActiveParentId(parentId);
    // console.log('Double Click Status', status);
    setIsLoadingContent(!isNodeClicked);
    setShowDetailModal(true);

    // await OpenAICall(nodeText, parentId); // This function now becomes async
    // setIsLoadingContent(false); // End loading after the call is complete
    handleNodeClick(id);
    Mixpanel.track('Node explored');
  };

  return (
    <div
      className="canvas-area"
      style={{ cursor: isPanning ? 'move' : 'default' }}
    >
      {mainQuestion && (
        <>
          <motion.button
            id="synthesis-button"
            onHoverStart={() => eqControls.start('animate')}
            onHoverEnd={() => eqControls.start(eqInitialVariant)}
            onClick={() => {
              setShowSynthesisModal(true);
              setIsLoadingSynthesis(reduxStoreData.length === 0 ? false : true);
            }}
          >
            <p>Synthesize research</p>{' '}
            <motion.svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_872_83)">
                <motion.path
                  d="M8 18C8.55 18 9 17.55 9 17V7C9 6.45 8.55 6 8 6C7.45 6 7 6.45 7 7V17C7 17.55 7.45 18 8 18ZM8 18C8.55 18 9 17.55 9 17V7C9 6.45 8.55 6 8 6C7.45 6 7 6.45 7 7V17C7 17.55 7.45 18 8 18ZM8 18C8.55 18 9 17.55 9 17V7C9 6.45 8.55 6 8 6C7.45 6 7 6.45 7 7V17C7 17.55 7.45 18 8 18ZM8 18C8.55 18 9 17.55 9 17V7C9 6.45 8.55 6 8 6C7.45 6 7 6.45 7 7V17C7 17.55 7.45 18 8 18ZM8 18C8.55 18 9 17.55 9 17V7C9 6.45 8.55 6 8 6C7.45 6 7 6.45 7 7V17C7 17.55 7.45 18 8 18Z"
                  fill="#575073"
                  animate={eqControls}
                  variants={variants2}
                />
                <motion.path
                  d="M13 21C13 21.55 12.55 22 12 22C11.45 22 11 21.55 11 21V3C11 2.45 11.45 2 12 2C12.55 2 13 2.45 13 3V21Z"
                  fill="#575073"
                  animate={eqControls}
                  variants={variants3}
                />
                <motion.path
                  d="M5 13C5 13.55 4.55 14 4 14C3.45 14 3 13.55 3 13V11C3 10.45 3.45 10 4 10C4.55 10 5 10.45 5 11V13Z"
                  fill="#575073"
                  animate={eqControls}
                  variants={variants1}
                />
                <motion.path
                  d="M17 17C17 17.55 16.55 18 16 18C15.45 18 15 17.55 15 17V7C15 6.45 15.45 6 16 6C16.55 6 17 6.45 17 7V17Z"
                  fill="#575073"
                  animate={eqControls}
                  variants={variants4}
                />
                <motion.path
                  d="M19 13V11C19 10.45 19.45 10 20 10C20.55 10 21 10.45 21 11V13C21 13.55 20.55 14 20 14C19.45 14 19 13.55 19 13Z"
                  fill="#575073"
                  animate={eqControls}
                  variants={variants5}
                />
              </g>
              <defs>
                <clipPath id="clip0_872_83">
                  <rect width="24" height="24" fill="none" />
                </clipPath>
              </defs>
            </motion.svg>
          </motion.button>
        </>
      )}

      <div className="zoom-controls">
        <button onClick={zoomOut} disabled={camera.z <= MIN_ZOOM}>
          -
        </button>
        <span>{Math.round(camera.z * 100)}%</span>
        <button onClick={zoomIn} disabled={camera.z >= MAX_ZOOM}>
          +
        </button>
      </div>
      <div
        id="canvas-container"
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onWheel={handleWheel}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden', // Hide overflow when content is dragged out of view
        }}
      >
        <div className="grid-background" style={gridStyle}></div>
        <div
          ref={canvasContentRef}
          id="canvas-content"
          style={{
            transform: `translate(${camera.x}px, ${camera.y}px) scale(${camera.z})`,
            transformOrigin: 'center',
            marginTop: '28px',
          }}
        >
          <>
            <svg className="lines-container">
              {nodes.map((node, index) => {
                // Skip the main question as it has no parent
                if (node.level === 0) return null;

                const parentNode = nodes.find((n) => n.id === node.parentId);
                return (
                  <motion.line
                    initial={{ pathLength: 0, filter: 'blur(2px)' }}
                    animate={{
                      pathLength: 1,
                      filter: isLoading ? 'blur(2px)' : 'blur(0px)',
                      transition: {
                        type: 'spring',
                        bounce: 0,
                        duration: 1.5,
                        delay: isLoading ? index * 0.9 : 0,
                      },
                    }}
                    key={`line-${node.id}`}
                    x1={parentNode.position.x + 100} // Assuming the parent node's width is 100px
                    y1={parentNode.position.y + 50} // Assuming the parent node's height is 50px
                    x2={node.position.x + 100} // Assuming the current node's width is 100px
                    y2={node.position.y + 50} // Assuming the current node's height is 50px
                    stroke="#DEDAF2"
                    strokeWidth="1.45"
                    style={{
                      pathLength: 0,
                      pathSpacing: 1,
                      pathOffset: 1,
                    }}
                  />
                );
              })}
            </svg>
            {nodes.map((node, index) => (
              <Node
                key={node.id}
                node={node}
                onDrag={onDrag}
                onDoubleClick={nodeDoubleClickHandler}
                clickedNodes={clickedNodes}
                addChildNode={addChildNode}
                isLoading={isLoading}
                delay={index * 0.9}
              />
            ))}
          </>
        </div>
      </div>
    </div>
  );
};

export default CanvasArea;
