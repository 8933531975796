import React, { useState, useEffect, useCallback } from 'react';
import CanvasArea from './CanvasArea';
import './App.css';
import OpenAI from 'openai';
import CreateModal from './CreateModal';
import Modal from './Modal';
import NewSpinner from './NewSpinner';
import { v4 as uuidv4 } from 'uuid';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import SynthesisModal from './SynthesisModal';
import { useSelector, useDispatch } from 'react-redux';
import { highlightsActions } from './store/store';
import { Mixpanel } from './Mixpanel';

const App = () => {
  useEffect(() => {
    Mixpanel.identify();
  }, []);

  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedNodes, setClickedNodes] = useState(['']);
  const [question, setQuestion] = useState('');
  const [context, setContext] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showSynthesisModal, setShowSynthesisModal] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [responseObject, setResponseObject] = useState({});
  const [activeSubQuestion, setActiveSubQuestion] = useState('');
  const [storedExplorations, setStoredExplorations] = useState([]);
  const [loadedInstance, setLoadedInstance] = useState('');
  const [isLoadingSynthesis, setIsLoadingSynthesis] = useState(false);
  const [isLoadingLimitations, setIsLoadingLimitations] = useState(false);
  const [isLoadingInterviewData, setIsLoadingInterviewData] = useState(false);
  const [fetchedUrls, setFetchedUrls] = useState([]);

  const mockInterviewData = [
    {
      interviewType: 'Loading interview type...',
      description: 'Loading description...',
      content: [
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
      ],
    },
    {
      interviewType: 'Loading interview type...',
      description: 'Loading description...',
      content: [
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
      ],
    },
    {
      interviewType: 'Loading interview type...',
      description: 'Loading description...',
      content: [
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
        {
          stakeholder: 'Loading stakeholder...',
          questions: [
            'Loading question...',
            'Loading question...',
            'Loading question...',
          ],
        },
      ],
    },
  ];

  const mockLimitations = [
    {
      title: 'Loading limitation...',
      description: 'Loading description...',
    },
    {
      title: 'Loading limitation...',
      description: 'Loading description...',
    },
    {
      title: 'Loading limitation...',
      description: 'Loading description...',
    },
    {
      title: 'Loading limitation...',
      description: 'Loading description...',
    },
  ];

  const mockData = {
    keyIdea: {
      text: 'Loading summary...',
    },
    summary: {
      text: 'Loading summary...',
    },
    expandedSummary: {
      text: 'Loading summary...',
    },
    highlightSummaries: [
      {
        nodeId: 'sub-1',
        summary: 'Loading summary...',
      },
      {
        nodeId: 'sub-2',
        summary: 'Loading summary...',
      },
      {
        nodeId: 'sub-3',
        summary: 'Loading summary...',
      },
      {
        nodeId: 'sub-4',
        summary: 'Loading summary...',
      },
      {
        nodeId: 'sub-1-1',
        summary: 'Loading summary...',
      },
    ],
  };

  const [synthesisData, setSynthesisData] = useState(mockData);
  const [limitationsData, setLimitationsData] = useState(mockLimitations);
  const [interviewData, setInterviewData] = useState(mockInterviewData);

  const selectAllNodeDetails = (state) => {
    const nodes = state.highlights.highlightsByNode;
    return Object.keys(nodes.highlights).map((nodeId) => ({
      nodeId: nodeId,
      highlights: [
        ...nodes.highlights[nodeId].summary,
        ...nodes.highlights[nodeId].expandedSummary,
      ],
      question: nodes.question[nodeId],
    }));
  };

  const reduxStoreData = useSelector(selectAllNodeDetails);
  const allNodeDetails = JSON.stringify(reduxStoreData);
  const dispatch = useDispatch();

  const queryClient = new QueryClient();

  const mockResponse = [
    {
      id: 'root',
      number: '',
      header: 'Main question',
      text: 'How to address climate change?',
      children: [
        {
          id: 'sub-1',
          number: '1.0',
          header: 'Understanding climate change',
          text: 'What are the current dynamics and drivers of climate change?',
          children: [
            {
              id: 'sub-1-1',
              number: '1.1',
              header: 'Main climate change factors',
              text: 'What are the main factors contributing to climate change?',
            },
            {
              id: 'sub-1-2',
              number: '1.2',
              header: 'Impact of those factors',
              text: 'What is the impact of these factors on the environment and global ecosystem?',
            },
          ],
        },
        {
          id: 'sub-2',
          number: '2.0',
          header: 'Technology role',
          text: 'How can technology help mitigate climate change effects?',
          children: [
            {
              id: 'sub-2-1',
              number: '2.1',
              header: 'Mitigating technologies',
              text: 'What emerging technologies are effective in climate change mitigation?',
            },
            {
              id: 'sub-2-2',
              number: '2.2',
              header: 'Technology adoption barriers',
              text: 'What are the barriers to adopting these technologies?',
            },
          ],
        },
        {
          id: 'sub-3',
          number: '3.0',
          header: 'Regulatory measures',
          text: 'How can regulations enforce climate change mitigation?',
          children: [
            {
              id: 'sub-3-1',
              number: '3.1',
              header: 'Effective regulatory mechanisms',
              text: 'What are the most effective regulatory mechanisms for mitigation?',
            },
            {
              id: 'sub-3-2',
              number: '3.2',
              header: 'Influence of regulations',
              text: 'How can these regulations influence commercial and industrial activities?',
            },
          ],
        },
        {
          id: 'sub-4',
          number: '4.0',
          header: 'Public awareness and behavior',
          text: 'How can public awareness and behavior impact climate change?',
          children: [
            {
              id: 'sub-4-1',
              number: '4.1',
              header: 'Role of public awareness',
              text: 'What role does public awareness play in climate change mitigation?',
            },
            {
              id: 'sub-4-2',
              number: '4.2',
              header: 'Shaping public behavior',
              text: 'How can public behavior be influenced to support climate-positive actions?',
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    const storedExplorations =
      JSON.parse(localStorage.getItem('past-explorations')) || [];

    setStoredExplorations(storedExplorations);
  }, []);

  const processAndSetNodes = (subQuestions) => {
    const yStep = 280; // Adjust vertical spacing
    const processQuestions = (
      questions,
      level = 0,
      parentId = null,
      parentX = 1600,
      siblingsCount = 0,
      topmostSubQuestionId = null
    ) => {
      const xInitialOffset = 700; // The initial X offset to center the root node
      let nodes = [];
      let nodeWidth = 250; // Width of the node, adjust as necessary
      let xPosition;
      let bottomMostNodePadding = 300; // Extra space between bottom-most nodes, adjust as needed
      let horizontalSpacingMultiplier = 2.8; // Increase for more horizontal space

      questions.forEach((question, index) => {
        let totalChildrenWidth = 0;
        let isBottomMostNode =
          !question.children || question.children.length === 0;
        let minWidthPerChild = nodeWidth * horizontalSpacingMultiplier;

        if (!isBottomMostNode) {
          // Adjusted for more space
          totalChildrenWidth = question.children.length * minWidthPerChild;
        }

        // Calculate the starting X position for the first child
        let childrenStartX = parentX - totalChildrenWidth / 2 + nodeWidth / 2;

        if (isBottomMostNode) {
          // Space bottom-most nodes more for clearer separation
          xPosition =
            parentX + (index - (siblingsCount - 1) / 2) * bottomMostNodePadding;
        } else {
          // Adjust xPosition calculation for non-bottom-most nodes
          xPosition = childrenStartX + minWidthPerChild * index;
        }

        if (level === 1) {
          topmostSubQuestionId = question.id;
        }

        const currentNode = {
          id: question.id,
          parentId: parentId,
          text: question.text,
          header: question.header,
          number: question.number,
          position: { x: xPosition, y: level * yStep },
          level: level,
          topmostSubQuestionId: topmostSubQuestionId,
          children: question.children,
        };

        nodes.push(currentNode);

        if (!isBottomMostNode) {
          const childNodes = processQuestions(
            question.children,
            level + 1,
            question.id,
            xPosition,
            question.children.length,
            topmostSubQuestionId
          );
          nodes = nodes.concat(childNodes);
        }
      });

      return nodes;
    };

    const initialNodes = processQuestions(subQuestions);
    setNodes(initialNodes);
  };

  const saveInstance = (instanceId, question, response) => {
    const explorations =
      JSON.parse(localStorage.getItem('past-explorations')) || [];

    explorations.unshift({
      id: instanceId,
      mainQuestion: question,
      response: response,
    });
    localStorage.setItem('past-explorations', JSON.stringify(explorations));
    setStoredExplorations(explorations);
  };

  const createNewInstance = (response) => {
    const newInstanceId = uuidv4();
    saveInstance(newInstanceId, question, response);
    setLoadedInstance(newInstanceId);
  };

  const loadInstance = (explorationId) => {
    // Find the exploration by id
    const exploration = storedExplorations.find((e) => e.id === explorationId);
    if (exploration) {
      // Assuming the response stored in exploration is already in the correct format
      processAndSetNodes(exploration.response);
      setLoadedInstance(exploration.id);
      setClickedNodes(['']);
      dispatch(highlightsActions.resetHighlights());
      setQuestion(exploration.mainQuestion);
    }
  };

  // SERPAPI CALL

  const serpAPIResponse = async (mainQuestion, subQuestion) => {
    try {
      const fullQuery = `${mainQuestion} AND ${subQuestion}`;
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/search?query=${encodeURIComponent(
          fullQuery
        )}`
      );
      const data = await response.json();

      // console.log('serAPI data:', data);

      // Extract the first six organic results
      const firstEightResults = data.organic_results.slice(0, 8);

      // Map the extracted results to the desired format
      const urls = firstEightResults.map((result) => {
        return {
          title: result.title,
          domain: new URL(result.link).hostname, // Extract the domain from the result link
          url: result.link,
        };
      });

      // Return the final object
      setFetchedUrls(urls);
    } catch (error) {
      console.error('Error fetching data:', error);
      return { urls: [] }; // Return an empty array if there's an error
    }
  };

  // OPENAI CALLS

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_API_KEY,
    organization: process.env.REACT_APP_ORG_KEY,
    dangerouslyAllowBrowser: true,
  });

  const OpenAICall = async () => {
    if (!question) return;

    setIsLoading(true);
    // console.log('called!');

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'You are a world-class consultant at McKinsey & Company, being excellent at breaking down complex problem following the MECE framework and the Pyramid Principle by Barbara Miinto. You should make clients aware of valuable and non-obvious (meaning not general or easily discerned) key questions that are critical to address their main question. You should always strictly follow the instruction by the user for your response.',
          },
          {
            role: 'user',
            content: `Take a deep breath. I have a complex question that needs breaking down for thorough research, I want you to take the role of a world-class McKinsey consultant following the Pyramid Principle and MECE framework as discussed by Barbara Miinto. You should provide highly valuable breakdowns, including critical aspects that clients themselves would not be aware of or think of.

            Each sub-question should highlight critical and highly valuable aspects of the main question that need to be explored first, before being able to optimally answer/address the main question. There should be 4 high-level sub-questions, and they should have 2 child questions. Meaning there are only 2 layers of sub-questions following the main question.

            My main question is: "${question}", and here is some additional context you should strongly consider that should be reflected in the overall breakdown and the devised sub-questions: "${context}"

            Based on this additional context, please break down the main question into sub-questions in a hierarchical structure (thoughtfully using the Miinto Pyramid Principle; and ensuring the breakdown is Mutually Exclusive and Collectively Exhaustive).

            Please format your response in a structured JSON format as shown below.

            This means the only thing you would for example write back to me should ONLY be:
            
{
  "id": "root",
  "number": "",
  "header": "Main question",
  "text": "What are the challenges and opportunities in renewable energy production?",
  "children": [
    {
      "id": "sub-1",
      "number": "1.0",
      "header": "Costs of energy production",
      "text": "What are the current costs of renewable energy production?",
      "children": [
        {
          "id": "sub-1-1",
          "number": "1.1",
          "header": "Key cost factors of solar energy",
          "text": "What factors contribute to the high costs of solar energy?"
        },
        {
          "id": "sub-1-2",
          "number": "1.2",
          "header": "Key cost factors of wind energy",
          "text": "What factors contribute to the high costs of wind energy?"
        }
      ]
    },
    {
      "id": "sub-2",
      "number": "2.0",
      "header": "Reducing energy costs with technology",
      "text": "How can technological advancements reduce the costs of renewable energy?",
      "children": [
        {
          "id": "sub-2-1",
          "number": "2.1",
          "header": "Emerging technologies to reduce costs",
          "text": "What are the emerging technologies in renewable energy that show promise for cost reduction?"
        },
        {
          "id": "sub-2-2",
          "number": "2.2",
          "header": "Impact of digitalization on costs",
          "text": "How does digitalization impact the costs of renewable energy production?"
        }
      ]
    },
    {
      "id": "sub-3",
      "number": "3.0",
      "header": "Environmental impacts",
      "text": "What are the environmental impacts of renewable energy production?",
      "children": [
        {
          "id": "sub-3-1",
          "number": "3.1",
          "header": "Impact on land use",
          "text": "What is the impact of renewable energy production on land use?"
        },
        {
          "id": "sub-3-2",
          "number": "3.2",
          "header": "Biodiversity concerns",
          "text": "What are the biodiversity concerns related to renewable energy projects?"
        }
      ]
    },
    {
      "id": "sub-4",
      "number": "4.0",
      "header": "Government policies and incentives",
      "text": "How do government policies and incentives affect the development of renewable energy?",
      "children": [
        {
          "id": "sub-4-1",
          "number": "4.1",
          "header": "Policies promoting renewable energy",
          "text": "What policies are currently in place to promote renewable energy?"
        },
        {
          "id": "sub-4-2",
          "number": "4.2",
          "header": "Incentives for investors",
          "text": "What incentives are available for investors in renewable energy projects?"
        }
      ]
    }
  ]
}

            
The header property of each question should briefly summarise the question and be a maximum of 6 words.

            ONLY GIVE ME AN OUTPUT WITH THIS JSON FORMAT, THIS IS VERY IMPORTANT. Nothing else unnecessary in terms of explanations of words should be included in your response.`,
          },
        ],
        model: 'gpt-4o',
        response_format: { type: 'json_object' },
      });

      // console.log(completion.choices[0].message.content);
      const output = completion.choices[0].message.content;
      const outputObject = JSON.parse(output);
      const outputArray = [outputObject];
      processAndSetNodes(outputArray); //MOCK IN DEV
      //   console.log('Main Q: ' + question);
      // console.log('Context: ' + context);
      createNewInstance(outputArray);
    } catch (error) {
      console.error('Error calling the OpenAI Chat API:', error);
    }

    setIsLoading(false);
  };

  const handleQuestionSubmit = useCallback(async () => {
    if (question.trim() !== '') {
      processAndSetNodes(mockResponse);
      setClickedNodes(['']);
      OpenAICall();
      setShowModal(false);
      Mixpanel.track('Create exploration');
    }
  }, [question, context]);

  const SynthesisOpenAICall = async () => {
    if (!question) return;
    if (reduxStoreData.length === 0) return;
    // console.log('allNodeDetails in App.js from OpenAI Call:', allNodeDetails);
    setIsLoadingLimitations(true);
    setIsLoadingInterviewData(true);
    // console.log('called SynthesisOpenAICall!');
    Mixpanel.track('Create synthesis');

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'You are a world-class analyst at McKinsey & Company, being excellent at synthesising data following the Pyramid Principle by Barbara Miinto. You should make users aware of valuable and non-obvious information. You should always strictly follow the instruction by the user for your response.',
          },
          {
            role: 'user',
            content: `Take a deep breath. Act as a highly proficient research analyst, emulating the meticulous research approach of a McKinsey analyst. I will provide you with an array of objects to process below (each object has a nodeId, research highlights, and the research question they are derived from). You have 4 objectives: 1) Synthesise: Process research highlights for each node and provide a synthesised summary covering the key idea each node’s highlights (only up to 40 words each). 2) Summarize: Provide a highly valable concise, precise, and intuitive bullet-pointed summary (only up to 150 words) that synthesises the highlights across all nodes. 3) Expand on summary: Provide an additional expanded version of the previous summary (only up to 500 words) where you go into more depth covering the specified question clearly, precisely, and intuitively. 4) Extract: Extract and intuitively synthesise the key idea of the expandedSummary (only up to 50 words).

            All summaries should include highly valuable and targeted information, outlining essential but also critical aspects that clients themselves would easily not be aware of or think of.
          
            Format your response as follows, adhering strictly to this JSON structure as an object with 4 properties:
                                 {
                keyIdea: {
                  text: 'Key summarising idea of all highlights, max 50 words'
                },
                summary: {
                  text: 'Your summary here, max 150 words.',
                },
                expandedSummary: {
                  text: 'Your expanded summary here, max 500 words',
                },
                highlightSummaries: [
                  {
                    nodeId: 'ID of the node',
		nodeQuestion: ‘question value of node’.
                    summary: 'Your summary of the node highlights here, max 40 words',
                  },
                  {
                    nodeId: 'ID of the node',
		nodeQuestion: ‘question value of node’.
                    summary: 'Your summary of the node highlights here, max 40 words',
                  },
                  {
                    nodeId: 'ID of the node',
		nodeQuestion: ‘question value of node’.
                    summary: 'Your summary of the node highlights here, max 40 words',
                  },
                  {
                    nodeId: 'ID of the node’',
		nodeQuestion: ‘question value of node’.
                    summary: 'Your summary of the node highlights here, max 40 words',
                  },
                  {
                    nodeId: 'ID of the node',
		nodeQuestion: ‘question value of node’.
                    summary: 'Your summary of the node highlights here, max 40 words',
                  },
                ],
              };  
            
            ONLY GIVE ME A RESPONSE WITH THIS STRICT json FORMAT, THIS IS VERY IMPORTANT. Nothing else unnecessary in terms of explanations of words should be included in your response. HERE IS THE ARRAY OF OBJECTS  I WANT YOU TO PROCESS ${allNodeDetails}. Carefully consider the highlights in each node, the question property of each node, and the main research question I am exploring all this through: ${question}. All this context should influence and tailor the summaries you generate.


            `,
          },
        ],
        model: 'gpt-4o',
        response_format: { type: 'json_object' },
      });

      // console.log(completion.choices[0].message.content);
      const output = completion.choices[0].message.content;
      const outputObject = JSON.parse(output);
      // console.log('Synthesis OpenAI data', outputObject);
      setIsLoadingSynthesis(false);
      return outputObject;
    } catch (error) {
      console.error('Error calling the Synthesis OpenAI Call:', error);
    }
  };

  const LimitationsOpenAICall = async (expandedSummary) => {
    if (!question) return;
    if (reduxStoreData.length === 0) return;
    // console.log('called LimitationsOpenAICall!');

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'You are a world-class analyst at McKinsey & Company, being excellent at identifying limitations and gaps in research data following the Pyramid Principle way of thinking by Barbara Miinto. You should make users aware of highly critical information. You should always strictly follow the instruction by the user for your response.',
          },
          {
            role: 'user',
            content: `Take a deep breath. Act as a highly proficient research analyst, emulating the meticulous research approach of a McKinsey analyst. I will provide you with my main research question and a research summary I want you to carefully scrutinise to identify possible limitations and gaps in my research to optimally address my main question.

            I want you to output 4 to 5 highly critical limitations/gaps I might have in my research, strictly following the example JSON structure below. Each limitation object should have a succinct title and a highly valuable yet digestible description of the research limitation/gap in max 50 words.
            
            {
               "data": [
                {
                  "title": "E.g. Ethical considerations",
                  "description": "E.g. The ethical implications of LLM deployment in design, such as ensuring fairness, privacy, and avoiding bias in AI-generated content."
                },
                {
                  "title": "E.g. Technical limitations",
                  "description": "E.g. The limitations related to the current state of technology, including computational resources and the accuracy of AI-generated designs."
                },
                {
                  "title": "E.g. User experience challenges",
                  "description": "E.g. The challenges in ensuring a seamless user experience while integrating AI-generated content into design workflows."
                },
                {
                  "title": "E.g. Regulatory compliance",
                  "description": "E.g. The need to comply with regulations and standards that govern the use of AI in design."
                },
                {
                  "title": "E.g. Cost considerations",
                  "description": "E.g. The cost implications of deploying and maintaining LLM systems in the design process."
                }
              ]
            }
                        
            ONLY GIVE ME A RESPONSE WITH THIS STRICT json FORMAT, THIS IS VERY IMPORTANT. Nothing else unnecessary in terms of explanations of words should be included in your response. Here is my main research question: ${question}, and here is the research summary I want you to process ${expandedSummary}. All this context should influence your limitations/gap analysis.
            `,
          },
        ],
        model: 'gpt-4o',
        response_format: { type: 'json_object' },
      });

      // console.log(completion.choices[0].message.content);
      const output = completion.choices[0].message.content;
      const outputObject = JSON.parse(output);
      // console.log('Limitations OpenAI data', outputObject);
      setIsLoadingLimitations(false);
      return outputObject.data;
    } catch (error) {
      console.error('Error calling the Limitations OpenAI Call:', error);
    }
    // setIsLoading(false);
  };

  const InterviewOpenAICall = async (limitations) => {
    if (!question) return;
    if (reduxStoreData.length === 0) return;
    // console.log('called InterviewOpenAICall!');

    try {
      const completion = await openai.chat.completions.create({
        messages: [
          {
            role: 'system',
            content:
              'You are a world-class analyst at McKinsey & Company, being excellent at identifying key stakeholders and interview question to ask to optimally address research limitations and gaps. You should follow the Pyramid Principle way of thinking by Barbara Miinto. You should make users aware of highly critical stakeholders and questions to ask. You should always strictly follow the instruction by the user for your response.',
          },
          {
            role: 'user',
            content: `Take a deep breath. Act as a highly proficient research analyst, emulating the meticulous research approach of a McKinsey analyst. I will provide you with my main research question and an array of key limitations/gaps in my research. I want you to carefully scrutinise this information and carefully outline stakeholders I should interview while providing me with highly valuable interview questions to ask each of them.

            I want you to output 3 interview categories/types and 2-3 key stakeholders in each category. Strictly follow the example JSON structure below. Each object should have an interviewType, description, and content with 2-3 key stakeholders and 3 questions for each of them. The goal is for this interview guide to help me better address and understand the limitations and gaps in my research.
            
            {
              "data": [
                {
                  "interviewType": "Expert interviews",
                  "description": "These questions are designed to elicit deep insights from experts in various fields, helping to identify both challenges and opportunities in the deployment of large language models (LLMs).",
                  "content": [
                    {
                      "stakeholder": "Cognitive scientist",
                      "questions": [
                        "How do cognitive biases influence user interactions with LLMs?",
                        "In what ways can LLMs be used to augment cognitive processes in educational settings?",
                        "What ethical considerations should be prioritized when LLMs are used in cognitive research?"
                      ]
                    },
                    {
                      "stakeholder": "Machine Learning Researchers",
                      "questions": [
                        "What are the biggest challenges in training LLMs with limited data?",
                        "How can we improve the interpretability of LLMs for non-expert users?",
                        "What recent advancements in machine learning could significantly impact the development of LLMs?"
                      ]
                    },
                    {
                      "stakeholder": "Policy-makers",
                      "questions": [
                        "What regulations are needed to ensure ethical use of LLMs?",
                        "How can policy facilitate innovation while protecting user privacy with LLMs?",
                        "What role should government play in the development and deployment of LLM technologies?"
                      ]
                    }
                  ]
                },
                {
                  "interviewType": "End-user interviews",
                  "description": "This series focuses on understanding the direct impact of LLMs on end-users across various sectors, aiming to optimize user experience and operational efficiency.",
                  "content": [
                    {
                      "stakeholder": "Corporate Executives",
                      "questions": [
                        "How has the introduction of LLMs changed strategic decision-making in your industry?",
                        "What feedback have you received from employees on the integration of LLMs into their workflows?",
                        "What are the key performance indicators that you use to measure the success of LLM implementations?"
                      ]
                    },
                    {
                      "stakeholder": "Healthcare Professionals",
                      "questions": [
                        "How do LLMs assist in patient care and diagnosis processes?",
                        "What challenges have you faced in adopting LLMs within clinical settings?",
                        "Can you provide examples of how LLMs have improved outcomes or efficiency in your practice?"
                      ]
                    },
                    {
                      "stakeholder": "Educators",
                      "questions": [
                        "In what ways have LLMs transformed teaching methods and student engagement?",
                        "What are the main concerns of educators regarding the use of AI in classrooms?",
                        "How do you foresee the future role of LLMs in education?"
                      ]
                    }
                  ]
                }
              ]
            }
                        
            ONLY GIVE ME A RESPONSE WITH THIS STRICT EXAMPLE json FORMAT, THIS IS VERY IMPORTANT. Nothing else unnecessary in terms of explanations of words should be included in your response. Here is my main research question: ${question}, and here are the research limitations and gaps I want you to process ${limitations}. All this context should influence your output.
            `,
          },
        ],
        model: 'gpt-4o',
        response_format: { type: 'json_object' },
      });

      // console.log(completion.choices[0].message.content);
      const output = completion.choices[0].message.content;
      const outputObject = JSON.parse(output);
      // console.log('Interview OpenAI data', outputObject);
      setIsLoadingInterviewData(false);
      return outputObject.data;
    } catch (error) {
      console.error('Error calling the Interview OpenAI Call:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!showSynthesisModal) return;
      const data = await SynthesisOpenAICall();
      if (data) {
        setSynthesisData(data);
        const limitationsData = await LimitationsOpenAICall(
          data.expandedSummary
        );
        if (limitationsData) {
          setLimitationsData(limitationsData);
          const interviewData = await InterviewOpenAICall(limitationsData.data);
          if (interviewData) {
            setInterviewData(interviewData);
          }
        }
      }
    };
    fetchData();
  }, [showSynthesisModal]);

  return (
    <QueryClientProvider client={queryClient}>
      <div className="app">
        <AnimatePresence mode="wait">
          {showModal && (
            <div id="modal-container">
              <CreateModal
                setQuestion={setQuestion}
                setContext={setContext}
                handleQuestionSubmit={handleQuestionSubmit}
                question={question}
                context={context}
                setShowModal={setShowModal}
                setNodes={setNodes}
                nodes={nodes}
              />
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {showDetailModal && (
            <div id="modal-container">
              <Modal
                setShowDetailModal={setShowDetailModal}
                responseObject={responseObject}
                activeSubQuestion={activeSubQuestion}
                isLoadingContent={isLoadingContent}
                setResponseObject={setResponseObject}
                setIsLoadingContent={setIsLoadingContent}
                nodes={nodes}
                fetchedUrls={fetchedUrls}
              />
            </div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {showSynthesisModal && (
            <div id="modal-container">
              <SynthesisModal
                mainQuestion={question}
                setShowSynthesisModal={setShowSynthesisModal}
                data={synthesisData}
                reduxStoreData={reduxStoreData}
                limitations={limitationsData}
                interviewData={interviewData}
                isLoadingSynthesis={isLoadingSynthesis}
                isLoadingLimitations={isLoadingLimitations}
                isLoadingInterviewData={isLoadingInterviewData}
              />
            </div>
          )}
        </AnimatePresence>
        <div className="explorations">
          <div id="new-exploration">
            <button
              className="exploration-action"
              id="create-exploration-action"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <p>New exploration</p>{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
              >
                <g clipPath="url(#clip0_986_43471)">
                  <path
                    d="M18 13H13V18C13 18.55 12.55 19 12 19C11.45 19 11 18.55 11 18V13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H11V6C11 5.45 11.45 5 12 5C12.55 5 13 5.45 13 6V11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_986_43471">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div id="past-explorations">
            {storedExplorations.map((exploration) => (
              <button
                key={exploration.id}
                className="exploration-action"
                onClick={() => loadInstance(exploration.id)}
                id={`${
                  loadedInstance === exploration.id &&
                  'exploration-action-active'
                }`}
              >
                <p>{exploration.mainQuestion}</p>
              </button>
            ))}
          </div>
        </div>
        {isLoading && (
          <>
            <div id="spinner">
              <NewSpinner />
            </div>
          </>
        )}
        <CanvasArea
          key={loadedInstance}
          nodes={nodes}
          setNodes={setNodes}
          isLoading={isLoading}
          clickedNodes={clickedNodes}
          setClickedNodes={setClickedNodes}
          setShowDetailModal={setShowDetailModal}
          activeSubQuestion={activeSubQuestion}
          setActiveSubQuestion={setActiveSubQuestion}
          setIsLoadingContent={setIsLoadingContent}
          setResponseObject={setResponseObject}
          processAndSetNodes={processAndSetNodes}
          isLoadingContent={isLoadingContent}
          showDetailModal={showDetailModal}
          setShowSynthesisModal={setShowSynthesisModal}
          SynthesisOpenAICall={SynthesisOpenAICall}
          mainQuestion={question}
          setIsLoadingSynthesis={setIsLoadingSynthesis}
          reduxStoreData={reduxStoreData}
          serpAPIResponse={serpAPIResponse}
        />
      </div>
    </QueryClientProvider>
  );
};

export default App;
